import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AuthContext } from '../../../common/context/auth-context';
import { useHttpClient } from '../../../common/hooks/http-hook';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddNote from './add-notes';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/styles';
import ErrorModal from '../../../common/UI/ErrorModal';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { PriorityHigh } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

const Notes = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [notes, setNotes] = useState(null);
  const [showAddNotes, setShowAddNotes] = useState(false);
  const [selectedNote, setSelectedNote] = useState();
  const [dateFilter, setDateFilter] = useState(false);
  const [callFilter, setCallFilter] = useState(false);
  const [emailFilter, setEmailFilter] = useState(false);
  const [notifyFilter, setNotifyFilter] = useState(false);
  const [meetingFilter, setMeetingFilter] = useState(false);
  const [fileFilter, setFileFilter] = useState(false);
  const [familyContactFilter, setFamilyContactFilter] = useState(false);
  const [clinicalContactFilter, setClinicalContactFilter] = useState(false);
  const [onboardingNotesFilter, setOnboardingNotesFilter] = useState(false);
  const [essentialInfoFilter, setEssentialInfoFilter] = useState(false);
  const classes = useStyles();
  const handleClose = () => {
    props.close();
  };

  useEffect(() => {
    const getIntake = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/api/accountable/getnotes',
          'POST',
          JSON.stringify({
            id: props.id
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
        if (responseData && !responseData.message) {
          let noteData = responseData;
          if (dateFilter) {
            noteData = noteData.filter((response) => response.date > dateFilter);
          }
          if (callFilter) {
            noteData = noteData.filter((response) => response.call);
          }
          if (emailFilter) {
            noteData = noteData.filter((response) => response.email);
          }
          if (meetingFilter) {
            noteData = noteData.filter((response) => response.checkin);
          }
          if (fileFilter) {
            noteData = noteData.filter((response) => response.file);
          }
          if (clinicalContactFilter) {
            noteData = noteData.filter((response) => response.clinicalContact);
          }
          if (familyContactFilter) {
            noteData = noteData.filter((response) => response.familyContact);
          }
          if (essentialInfoFilter) {
            noteData = noteData.filter((response) => response.essentialInfo);
          }

          setNotes(noteData);
        }
      } catch (err) {
        console.error(err);
      }
    };
    getIntake();
  }, [
    props.id,
    sendRequest,
    callFilter,
    emailFilter,
    dateFilter,
    notifyFilter,
    meetingFilter,
    fileFilter,
    clinicalContactFilter,
    familyContactFilter,
    essentialInfoFilter,
    auth.token
  ]);

  const addNote = () => {
    setShowAddNotes(true);
  };

  const closeNote = (addNote) => {
    if (addNote && addNote.delete) {
      setNotes([...notes.filter((oldNote) => oldNote._id !== addNote.delete)]);
    } else if (addNote && !selectedNote) {
      const updatedNotes = [...notes, addNote].sort((a, b) => new Date(b.date) - new Date(a.date));
      setNotes(updatedNotes);
    } else if (addNote) {
      const updatedNotes = [
        ...notes.filter((oldNote) => oldNote._id !== addNote._id),
        addNote
      ].sort((a, b) => new Date(b.date) - new Date(a.date));
      setNotes(updatedNotes);
    }

    setShowAddNotes(false);
    setSelectedNote(null);
  };

  const onNoteClick = (event) => {
    setSelectedNote(event.currentTarget.attributes.value.nodeValue);
    setShowAddNotes(true);
  };
  const handleSendEmail = (event) => {
    setEmailFilter(!emailFilter);
  };
  const handlePhoneCall = (event) => {
    setCallFilter(!callFilter);
  };

  const handleMeetingFilter = (event) => {
    setMeetingFilter(!meetingFilter);
  };

  const handleFileFilter = (event) => {
    setFileFilter(!fileFilter);
  };

  const handleSetDate = (event) => {
    setDateFilter(event.currentTarget.value);
  };

  const handleClinicalContactFilter = (event) => {
    setClinicalContactFilter(!clinicalContactFilter);
  };

  const handleOnboardingNotesFilter = (event) => {
    setOnboardingNotesFilter(!onboardingNotesFilter);
  };

  const handleFamilyContactFilter = (event) => {
    setFamilyContactFilter(!familyContactFilter);
  };

  const handleEssentialInfoFilter = (event) => {
    setEssentialInfoFilter(!essentialInfoFilter);
  };

  return (
    <>
      {error && (
        <ErrorModal
          open={!!error}
          title="Access Denied"
          alert={error}
          clearError={() => {
            clearError();
            handleClose();
          }}
        />
      )}
      <AddNote
        open={showAddNotes}
        close={closeNote}
        editVal={selectedNote}
        uid={props.id}
        auth={auth}
      />
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md">
        <DialogTitle>{props.name}'s Notes</DialogTitle>
        <DialogContent>
          <DialogContentText align="right">
            {(auth.accountableAdmin || auth.programProvider || auth.program || auth.provider) && (
              <Button color="secondary" onClick={addNote}>
                Add Note&nbsp;&nbsp;
                <AddCircleIcon color="secondary" fontSize="small" />
              </Button>
            )}
          </DialogContentText>
          <FormControl className={classes.formControl}>
            <InputLabel id="completedGoal-label" shrink>
              Only Phone Calls
            </InputLabel>
            <Checkbox
              className={classes.textField}
              fullWidth
              label="Phone Call"
              name="restrictedUser"
              onChange={handlePhoneCall}
              checked={callFilter}
              variant="outlined"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="completedGoal-label" shrink>
              Only Email
            </InputLabel>
            <Checkbox
              className={classes.textField}
              fullWidth
              label="Email Sent"
              name="restrictedUser"
              onChange={handleSendEmail}
              checked={emailFilter}
              variant="outlined"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="completedGoal-label" shrink>
              Only Meetings
            </InputLabel>
            <Checkbox
              className={classes.textField}
              fullWidth
              label="Only Meetings"
              name="restrictedUser"
              onChange={handleMeetingFilter}
              checked={meetingFilter}
              variant="outlined"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="completedGoal-label" shrink>
              Only Files
            </InputLabel>
            <Checkbox
              className={classes.textField}
              fullWidth
              label="Only Files"
              name="restrictedUser"
              onChange={handleFileFilter}
              checked={fileFilter}
              variant="outlined"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="completedGoal-label" shrink>
              Only Clinical Contact
            </InputLabel>
            <Checkbox
              className={classes.textField}
              fullWidth
              label="Only Clinical Contact"
              name="restrictedUser"
              onChange={handleClinicalContactFilter}
              checked={clinicalContactFilter}
              variant="outlined"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="completedGoal-label" shrink>
              Only Family Contact
            </InputLabel>
            <Checkbox
              className={classes.textField}
              fullWidth
              label="Only Family Contact"
              name="restrictedUser"
              onChange={handleFamilyContactFilter}
              checked={familyContactFilter}
              variant="outlined"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="completedGoal-label" shrink>
              Only Onboarding
            </InputLabel>
            <Checkbox
              className={classes.textField}
              fullWidth
              label="Only onboarding notes"
              name="restrictedUser"
              onChange={handleOnboardingNotesFilter}
              checked={onboardingNotesFilter}
              variant="outlined"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="completedGoal-label" shrink>
              Only Essential Info
            </InputLabel>
            <Checkbox
              className={classes.textField}
              fullWidth
              label="Only Essential Info"
              name="restrictedUser"
              onChange={handleEssentialInfoFilter}
              checked={essentialInfoFilter}
              variant="outlined"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              id="date"
              label="Start Date"
              type="date"
              defaultValue=""
              onChange={handleSetDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
            />
          </FormControl>
          <Divider />
          <DialogContentText>
            <List>
              {notes &&
                notes.length > 0 &&
                notes.map((val) => {
                  if (val) {
                    return (
                      <ListItem button onClick={onNoteClick} value={val._id} key={val._id}>
                        {val.call && (
                          <ListItemIcon>
                            <PhoneIcon />
                          </ListItemIcon>
                        )}
                        {val.essentialInfo && (
                          <ListItemIcon>
                            <PriorityHigh />
                          </ListItemIcon>
                        )}
                        {val.email && (
                          <ListItemIcon>
                            <EmailIcon />
                          </ListItemIcon>
                        )}
                        {val.file && (
                          <ListItemIcon>
                            <CloudDownloadIcon />
                          </ListItemIcon>
                        )}
                        <ListItemText
                          primary={
                            val.note.substr(0, 200) + ' -Written By: ' + val.Signature + '...'
                          }
                          secondary={new Date(val.date).toLocaleString() + ' ...Click to Read More'}
                        />
                      </ListItem>
                    );
                  }
                })}
              {notes && notes.length == 0 && (
                <ListItem button>
                  <ListItemText
                    primary="No notes have been entered yet."
                    secondary={new Date().toLocaleString()}
                  />
                </ListItem>
              )}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" value={false} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Notes;
