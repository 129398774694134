import React, { useContext } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { List, ListItem, Button, SxProps, Theme, colors, ButtonProps } from '@mui/material';
import { AuthContext } from '../../../common/context/auth-context';
import { theme } from '../../../theme';
import { PageItem } from './Sidebar';

interface SidebarNavProps {
  pages: PageItem[];
  sx?: SxProps<Theme>;
}

const SidebarNav: React.FC<SidebarNavProps> = ({ pages, sx }) => {
  const auth = useContext(AuthContext);

  const handleLogout = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    auth.logout();
  };
  return (
    <List disablePadding sx={sx}>
      {pages.map((page) => (
        <React.Fragment key={page.title}>
          {page.logout ? (
            <ListItem disableGutters sx={{ mb: 0.5, p: 0 }}>
              <NavButton
                to={page.href}
                icon={page.icon}
                title={page.title}
                onClick={handleLogout}
              />
            </ListItem>
          ) : (
            <>
              {!page.logout &&
                ((!page.User && (!page.hide || auth.accountableAdmin || auth.provider)) ||
                  (page.User && !auth.accountableAdmin && !auth.provider)) &&
                (!page.admin || auth.accountableAdmin) &&
                (!page.supervisor || auth.supervisor) &&
                (!page.harambe || auth.harambe) && (
                  <ListItem disableGutters sx={{ mb: 0.5, p: 0 }}>
                    <NavButton to={page.href} icon={page.icon} title={page.title} />
                  </ListItem>
                )}
              {!page.logout &&
                ((page.Program && auth.program) || (page.siteAdmin && auth.siteAdmin)) && (
                  <ListItem disableGutters sx={{ mb: 0.5, p: 0 }}>
                    <NavButton to={page.href} icon={page.icon} title={page.title} />
                  </ListItem>
                )}
            </>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

interface NavButtonProps extends ButtonProps {
  to: string;
  icon: React.ReactNode;
  title: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const NavButton = ({ to, icon, title, onClick, ...props }: NavButtonProps) => {
  const buttonSx: SxProps<Theme> = {
    color: colors.blueGrey[700],
    padding: '8px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: 'medium',
    '&.active': {
      color: theme.palette.primary.main,
      '& .icon': {
        color: theme.palette.primary.main
      }
    },
    ...props.sx
  };

  return (
    <Button component={RouterLink} to={to} onClick={onClick} sx={buttonSx} {...props}>
      <span
        className="icon"
        style={{
          marginRight: 8,
          display: 'flex',
          alignItems: 'center'
        }}>
        {icon}
      </span>
      {title}
    </Button>
  );
};

export default SidebarNav;
