import React, { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as LegacyThemeProvider } from '@material-ui/styles';
import { ThemeProvider } from '@mui/material/styles';
import { legacyTheme, theme } from './theme';
import './assets/scss/index.scss';
import LoadingSpinner from './common/UI/LoadingSpinner';
import Routes from './Routes';
import { useAuth } from './common/hooks/auth-hook';
import { AuthContext } from './common/context/auth-context';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Toaster, ToastBar, toast } from 'react-hot-toast';
import { WebSocketProvider } from './common/context/web-socket-context';
import { usePostHog } from 'posthog-js/react';
import PostHogPageView from './PageView';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE!);

const App: React.FC = () => {
  const auth = useAuth();
  const posthog = usePostHog();

  useEffect(() => {
    if (auth.token && auth.userId) {
      posthog.identify(auth.userId, { accountableAdmin: auth.accountableAdmin || false });
    }
  }, [auth.token, auth.userId]);

  return (
    <Elements stripe={stripePromise}>
      <AuthContext.Provider value={auth}>
        <WebSocketProvider>
          <LegacyThemeProvider theme={legacyTheme}>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <Suspense fallback={<LoadingSpinner open />}>
                  {auth.loading ? <LoadingSpinner open /> : <Routes />}
                  <PostHogPageView />
                  <Toaster position="top-right" toastOptions={{ duration: Infinity }}>
                    {(t) => (
                      <ToastBar toast={t}>
                        {({ icon, message }) => (
                          <>
                            {icon}
                            {message}
                            {t.type !== 'loading' && (
                              <button onClick={() => toast.dismiss(t.id)}>X</button>
                            )}
                          </>
                        )}
                      </ToastBar>
                    )}
                  </Toaster>
                </Suspense>
              </BrowserRouter>
            </ThemeProvider>
          </LegacyThemeProvider>
        </WebSocketProvider>
      </AuthContext.Provider>
    </Elements>
  );
};

export default App;
