import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { confirmable } from 'react-confirm';

const Confirmation = ({
  okLabel = 'Yes',
  cancelLabel = 'No',
  title = 'Confirmation',
  confirmation,
  show,
  proceed,
  dismiss,
  cancel,
  modal
}: {
  okLabel: string;
  cancelLabel: string;
  title: string;
  confirmation: string;
  show: boolean;
  proceed: () => void;
  dismiss: () => void;
  cancel: () => void;
  modal: boolean;
}) => {
  return (
    <Dialog open={show} onClose={dismiss}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{confirmation}</DialogContent>
      <DialogActions>
        <Button variant="text" onClick={cancel}>
          {cancelLabel}
        </Button>
        <Button variant="contained" onClick={proceed}>
          {okLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default confirmable(Confirmation);
