import React, { useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Typography
} from '@material-ui/core';
import ErrorModal from '../common/UI/ErrorModal';
import LoadingSpinner from '../common/UI/LoadingSpinner';
import { useHttpClient } from '../common/hooks/http-hook';
import { AuthContext } from '../common/context/auth-context';
import { useForm } from '../common/hooks/form-hook';
import backgroundImage from '../../public/images/vectors/Original.png';
import { required, maxLength, email, minLength, checked } from '../common/validators';

const schema = {
  firstName: [required, maxLength(32)],
  lastName: [required, maxLength(32)],
  company: [required, maxLength(32)],
  phoneNumber: [required, maxLength(32)],
  email: [required, email, maxLength(64)],
  password: [required, maxLength(128), minLength(6)],
  policy: [required, checked]
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },

  quote: {
    backgroundColor: theme.palette.background.light,
    height: '50%',
    display: 'flex',
    justifyContent: 'top',
    alignItems: 'top',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '100%',
    backgroundColor: theme.palette.background.light,
    height: '50%'
  },
  quoteText: {
    color: theme.palette.primary,
    fontWeight: 300,
    backgroundColor: theme.palette.background.light,
    marginLeft: '10px',
    marginRight: '10px'
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  }
}));

const ProgramSignUp = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  if (auth.isLoggedIn) auth.logout();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const classes = useStyles();
  const { hasError, handleChange, formState } = useForm(schema);

  const handleBack = () => {
    navigate(-1);
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/api/users/signup',
        'POST',
        JSON.stringify({
          firstName: formState.values.firstName,
          lastName: formState.values.lastName,
          phoneNumber: formState.values.phoneNumber,
          email: formState.values.email,
          password: formState.values.password,
          treatmentProgram: true,
          company: formState.values.company
        }),
        {
          'Content-Type': 'application/json'
        }
      );

      if (responseData.token) {
        auth.login(
          responseData.userId,
          responseData.email,
          responseData.tenant,
          responseData.token,
          null,
          true,
          responseData.status,
          false,
          false,
          false,
          true,
          true,
          false
        );
        navigate('/dashboard');
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classes.root}>
      {error && (
        <ErrorModal open={!!error} title="Error Logging In" alert={error} clearError={clearError} />
      )}
      {isLoading && <LoadingSpinner open={isLoading} />}
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote} />
          <div className={classes.quoteInner}>
            <Typography className={classes.quoteText} variant="h3">
              <b>Congratulations on taking this step in your recovery</b>
            </Typography>
            <br />
            <Typography className={classes.quoteText} variant="h4">
              <b>You're in good company</b>
            </Typography>
            <Typography className={classes.quoteText} variant="h5">
              <br />
              <i>
                "I can’t say enough positive things about Accountable! They have been super
                supportive in helping me transition back into life at home after my inpatient
                treatment. They are always there if I need to talk or need advice. They help me set
                weekly goals for therapy, meetings and workouts and hold me to it. I highly
                recommend using Accountable!"
              </i>
              <br />
              <br />
              -Sal B.
              <br />
              <br />
              <i>
                "Thank you so much for believing and supporting me! I would have NEVER EVER made it
                this far without you and this amazing program! There will be harder times and I'm
                sure lots of tears in my future but I'm more excited and positive that I can do it
                and am worth it....then I ever ever thought I'd be ever! Thank you Accountable!"
              </i>
              <br />
              <br />
              -Elisabeth D.
            </Typography>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack} />
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignUp}>
                <Typography className={classes.title} variant="h2" gutterBottom>
                  Welcome to Accountable's Provider Signup
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('firstName')}
                  fullWidth
                  helperText={hasError('firstName') ? formState.errors.firstName[0] : null}
                  label="First name"
                  name="firstName"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.firstName || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('lastName')}
                  fullWidth
                  helperText={hasError('lastName') ? formState.errors.lastName[0] : null}
                  label="Last name"
                  name="lastName"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.lastName || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('company')}
                  fullWidth
                  helperText={hasError('company') ? formState.errors.company[0] : null}
                  label="Company"
                  name="company"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.company || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('phoneNumber')}
                  fullWidth
                  helperText={hasError('phoneNumber') ? formState.errors.phoneNumber[0] : null}
                  label="Phone Number"
                  name="phoneNumber"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.phoneNumber || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={hasError('email') ? formState.errors.email[0] : null}
                  label="Email address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={hasError('password') ? formState.errors.password[0] : null}
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                />
                <div className={classes.policy}>
                  <Checkbox
                    checked={formState.values.policy || false}
                    className={classes.policyCheckbox}
                    color="primary"
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography className={classes.policyText} color="textSecondary" variant="body1">
                    I have read the{' '}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="/programterms"
                      underline="always"
                      variant="h6">
                      Terms and Conditions
                    </Link>
                  </Typography>
                </div>
                {hasError('policy') && (
                  <FormHelperText error>{formState.errors.policy[0]}</FormHelperText>
                )}
                <Button
                  className={classes.signUpButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained">
                  Sign up now
                </Button>
                <Typography color="textSecondary" variant="body1">
                  Have an account?{' '}
                  <Link component={RouterLink} to="/sign-in" variant="h6">
                    Sign in
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProgramSignUp;
