export const checked = (value: boolean) => {
  if (value !== true) {
    return ['Must be checked'];
  }
  return undefined;
};

export const required = (value: string | number) => {
  if (!value && value !== 0) {
    return ['This field is required'];
  }
  return undefined;
};

export const email = (value: string) => {
  if (!value) return undefined;
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  if (!emailRegex.test(value)) {
    return ['Invalid email address'];
  }
  return undefined;
};

export const maxLength = (max: number) => (value: string) => {
  if (!value) return undefined;
  if (value.length > max) {
    return [`Must be less than ${max} characters`];
  }
  return undefined;
};

export const minLength = (min: number) => (value: string) => {
  if (!value) return undefined;
  if (value.length < min) {
    return [`Must be at least ${min} characters`];
  }
  return undefined;
};

export const equality = (compareField: string) => (value: string, allValues: any) => {
  if (!value) return undefined;
  if (value !== allValues[compareField]) {
    return [`Must match ${compareField}`];
  }
  return undefined;
};

export default {
  checked,
  required,
  email,
  maxLength,
  equality
};
