export { default as common } from "./common.js";
export { default as red } from "./red.js";
export { default as pink } from "./pink.js";
export { default as purple } from "./purple.js";
export { default as deepPurple } from "./deepPurple.js";
export { default as indigo } from "./indigo.js";
export { default as blue } from "./blue.js";
export { default as lightBlue } from "./lightBlue.js";
export { default as cyan } from "./cyan.js";
export { default as teal } from "./teal.js";
export { default as green } from "./green.js";
export { default as lightGreen } from "./lightGreen.js";
export { default as lime } from "./lime.js";
export { default as yellow } from "./yellow.js";
export { default as amber } from "./amber.js";
export { default as orange } from "./orange.js";
export { default as deepOrange } from "./deepOrange.js";
export { default as brown } from "./brown.js";
export { default as grey } from "./grey.js";
export { default as blueGrey } from "./blueGrey.js";