import React, { useContext, useState, useEffect } from 'react';
import ErrorModal from '../../../common/UI/ErrorModal';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useForm } from '../../../common/hooks/form-hook';
import { AuthContext } from '../../../common/context/auth-context';
import LoadingSpinner from '../../../common/UI/LoadingSpinner';
import { useHttpClient } from '../../../common/hooks/http-hook';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import confirm from '../../../common/hooks/confirm';
import {
  SpeechConfig,
  AudioConfig,
  SpeechRecognizer
} from 'microsoft-cognitiveservices-speech-sdk';
import { required } from '../../../common/validators';

const schema = {
  note: [required]
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

const AddNote = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const { hasError, handleChange, formState } = useForm(schema);
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState(); //for edit
  const [phoneCall, setPhoneCall] = useState(false);
  const [checkin, setCheckin] = useState(false);
  const [sendNotification, setSendNotification] = useState(false);
  const [file, setFile] = useState();
  const [clinicalContact, setclinicalContact] = useState(false);
  const [familyContact, setfamilyContact] = useState(false);
  const [transcription, setTranscription] = useState('');
  const [recognizing, setRecognizing] = useState(false);
  const [key, setKey] = useState('');
  const [region, setRegion] = useState('');
  const [recognizers, setRecognizer] = useState('');
  const [email, setEmail] = useState(false);
  const [emailText, setEmailText] = useState('');
  const [openTemplate, setOpenTemplate] = useState(false);
  const [essentialInfo, setEssentialInfo] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const localToUTC = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  const UTCToLocal = (dateString) => {
    const date = new Date(dateString);
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      })
      .replace(' ', 'T');
  };

  const [noteDate, setNoteDate] = useState(UTCToLocal(new Date()));

  const startRecognition = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/api/gpt/getAWSKeys',
        'GET',
        null,
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
        }
      );
      setKey(responseData.accessKeyId);
      setRegion(responseData.region);
      // Set up the Azure Speech SDK
      const speechConfig = SpeechConfig.fromSubscription(
        responseData.accessKeyId,
        responseData.region
      );
      const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
      const recognizer = new SpeechRecognizer(speechConfig, audioConfig);
      setRecognizer(recognizer);

      // Set recognizing to true to show the user that recognition has started
      setRecognizing(true);

      // Attach event handlers
      recognizer.recognizing = () => {
        //setTranscription(e.result.text);
        // console.log(e.result.text)
      };

      recognizer.recognized = (s, e) => {
        if (e.result.text)
          setTranscription((prevTranscription) => `${prevTranscription} ${e.result.text}`);
        //console.log(e.result.text)
      };

      recognizer.canceled = () => {
        recognizer.stopContinuousRecognitionAsync();
        setRecognizing(false);
      };

      recognizer.sessionStopped = () => {
        recognizer.stopContinuousRecognitionAsync();
        setRecognizing(false);
      };

      // Start recognizing
      recognizer.startContinuousRecognitionAsync();

      // Set a timeout to stop recognition after 1 hour and 15 minutes (4,500,000 milliseconds)
      setTimeout(() => {
        if (recognizing) {
          stopRecognition();
          console.log('Speech recognition automatically stopped after 1 hour and 15 minutes.');
        }
      }, 4500000);
    } catch (err) {}
  };

  const stopRecognition = () => {
    recognizers.stopContinuousRecognitionAsync();
    setRecognizing(false);
  };

  useEffect(() => {
    //check if this form is for editing
    const getMessage = async () => {
      try {
        if (props.editVal) {
          const responseData = await sendRequest(
            process.env.REACT_APP_BACKEND_URL + '/api/accountable/getnote',
            'POST',
            JSON.stringify({
              id: props.editVal
            }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token
            }
          );
          if (responseData._id) {
            setEmail(responseData.email);
            setPhoneCall(responseData.call);
            setclinicalContact(responseData.clinicalContact);
            setfamilyContact(responseData.familyContact);
            setNoteDate(UTCToLocal(responseData.date));
            responseData.utcDate = responseData.date;
            responseData.date =
              new Date(responseData.date).getUTCFullYear() +
              '-' +
              ('0' + (new Date(responseData.date).getUTCMonth() + 1)).slice(-2) +
              '-' +
              ('0' + new Date(responseData.date).getUTCDate()).slice(-2) +
              'T' +
              new Date(responseData.date).getUTCHours() +
              ':' +
              ('0' + new Date(responseData.date).getUTCMinutes()).slice(-2);

            setNote(responseData);
          }
        }
      } catch (err) {}
    };
    formState.values.note = '';
    setNote(null);
    setNoteDate(UTCToLocal(new Date()));
    getMessage();
  }, [open]);

  useEffect(() => {
    //validate form from getgo.
    try {
      if (note) {
        const note = document.querySelector("input[name='note']");
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          'value'
        ).set;
        const nativeTextAreaValueSetter = Object.getOwnPropertyDescriptor(
          window.HTMLTextAreaElement.prototype,
          'value'
        ).set;
        nativeInputValueSetter.call(note, 'is working');
        const inputEvent = new Event('input', { bubbles: true });
        note.dispatchEvent(inputEvent);
      }
    } catch (err) {}
    if (recognizing) stopRecognition();
  }, [open, note]);

  const generateNote = async () => {
    const responseData = await sendRequest(
      process.env.REACT_APP_BACKEND_URL + '/api/gpt/generateNote',
      'POST',
      JSON.stringify({
        transcript: transcription
      }),
      {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + auth.token
      }
    );
    if (responseData.response) {
      setNote({ note: responseData.response });
    }
  };

  const generateEmail = async () => {
    const responseData = await sendRequest(
      process.env.REACT_APP_BACKEND_URL + '/api/gpt/generateCoordinationNote',
      'POST',
      JSON.stringify({
        transcript: formState.values.note || note.note
      }),
      {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + auth.token
      }
    );
    if (responseData.response) {
      setEmailText(responseData.response);
    }
  };

  useEffect(() => {
    if (formState.values.note !== (note?.note || '')) {
      setHasUnsavedChanges(true);
    }
  }, [formState.values.note, note]);

  const handleSave = async (event) => {
    event.preventDefault();
    let responseData = null;
    try {
      const formData = new FormData();
      formData.append('file', file);
      responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/api/accountable/setnotes',
        'POST',
        JSON.stringify({
          id: props.editVal,
          member: props.uid,
          note: formState.values.note,
          date: localToUTC(noteDate),
          email: email || sendNotification,
          call: phoneCall,
          notify: sendNotification,
          essentialInfo,
          checkin,
          meetingID: props.meetingID,
          clinicalContact,
          familyContact
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
        }
      );
      if (file && responseData._id) {
        try {
          formData.append('noteID', responseData._id);
          await sendRequest(
            process.env.REACT_APP_BACKEND_URL + '/api/upload/uploadDocs',
            'POST',
            formData,
            {
              Authorization: 'Bearer ' + auth.token
            }
          );
        } catch (err) {
          console.log(err);
        }
      }
      setFile(null);
      setHasUnsavedChanges(false);
      setOpen(false);
      props.close(responseData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (event) => {
    event.preventDefault();
    try {
      const deleteNote = await confirm('Are you sure you would like to delete this note?');
      if (deleteNote) {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/api/accountable/deletenote',
          'POST',
          JSON.stringify({
            noteid: props.editVal
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
        if (responseData.valid) {
          props.close({ delete: props.editVal }); //remove deleted object
        }
      }
    } catch (err) {}
  };

  const handleCancel = async (event) => {
    event.preventDefault();
    if (hasUnsavedChanges) {
      const shouldClose = await confirm(
        'You have unsaved changes. Are you sure you want to close?'
      );
      if (!shouldClose) {
        return;
      }
    }
    setHasUnsavedChanges(false);
    setOpen(false);
    props.close(false);
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleSendEmail = () => {
    setEmail(!email);
  };
  const handlePhoneCall = () => {
    setPhoneCall(!phoneCall);
  };

  const handleNotify = () => {
    setSendNotification(!sendNotification);
  };

  const handleCheckin = () => {
    setCheckin(!checkin);
  };
  const handleClinicalContact = () => {
    setclinicalContact(!clinicalContact);
  };

  const handleFamilyContact = () => {
    setfamilyContact(!familyContact);
  };

  const resetTranscription = () => {
    setTranscription(null);
  };
  const handleEssentialInfo = () => {
    setEssentialInfo(!essentialInfo);
  };

  return (
    <>
      {error && (
        <ErrorModal
          open={!!error}
          title="Error Creating Goal"
          alert={error}
          clearError={clearError}
        />
      )}
      {isLoading && <LoadingSpinner open={isLoading} />}
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm">
        <DialogTitle>Add/Edit Note</DialogTitle>
        <DialogContent>
          <TextField
            className={classes.textField}
            error={hasError('note')}
            fullWidth
            helperText={hasError('note') ? formState.errors.note[0] : null}
            label="Note"
            name="note"
            multiline
            onChange={handleChange}
            value={formState.values.note || (note && note.note) || ''}
            variant="outlined"
            disabled={
              !props.auth.accountableAdmin &&
              !props.auth.program &&
              !props.auth.programProvider &&
              !props.auth.provider
            }
          />
          {note && note.Signature && (
            <InputLabel>
              <br />
              {`Written By: ${note.Signature} on ${new Date(note.utcDate).toLocaleString()}`}
            </InputLabel>
          )}
          <TextField
            className={classes.textField}
            fullWidth
            label="Date and Time (Local)"
            name="noteDate"
            type="datetime-local"
            value={noteDate}
            onChange={(e) => setNoteDate(e.target.value)}
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
          />
          {(!note || (note && !note.file)) && (
            <TextField
              className={classes.textField}
              fullWidth
              label="File"
              name="file"
              onChange={(event) => {
                setFile(event.target.files[0]);
              }}
              type="file"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
          <br />
          {note && note.file && (
            <Button
              onClick={() => {
                window.open(note.file, '_blank');
              }}>
              Download File&nbsp;&nbsp;
              <CloudDownloadIcon />
            </Button>
          )}
          <br />
          <FormControl className={classes.formControl}>
            <InputLabel id="completedGoal-label" shrink>
              Phone Call
            </InputLabel>
            <Checkbox
              className={classes.textField}
              error={hasError('restrictedUser')}
              fullWidth
              helperText={hasError('restrictedUser') ? formState.errors.phoneCall[0] : null}
              label="Phone Call"
              name="restrictedUser"
              onChange={handlePhoneCall}
              checked={phoneCall}
              variant="outlined"
              disabled={
                !props.auth.accountableAdmin &&
                !props.auth.program &&
                !props.auth.programProvider &&
                !props.auth.provider
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="completedGoal-label" shrink>
              Email Sent
            </InputLabel>
            <Checkbox
              className={classes.textField}
              error={hasError('restrictedUser')}
              fullWidth
              helperText={hasError('restrictedUser') ? formState.errors.email[0] : null}
              label="Email Sent"
              name="restrictedUser"
              onChange={handleSendEmail}
              checked={email}
              variant="outlined"
              disabled={
                !props.auth.accountableAdmin &&
                !props.auth.program &&
                !props.auth.programProvider &&
                !props.auth.provider
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="completedGoal-label" shrink>
              Notify Care Team
            </InputLabel>
            <Checkbox
              className={classes.textField}
              error={hasError('restrictedUser')}
              fullWidth
              helperText={hasError('restrictedUser') ? formState.errors.notify[0] : null}
              label="Notify Care Team"
              name="restrictedUser"
              onChange={handleNotify}
              checked={sendNotification}
              variant="outlined"
              disabled={
                !props.auth.accountableAdmin &&
                !props.auth.program &&
                !props.auth.programProvider &&
                !props.auth.provider
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="completedGoal-label" shrink>
              Check-in Meeting
            </InputLabel>
            <Checkbox
              className={classes.textField}
              error={hasError('restrictedUser')}
              fullWidth
              helperText={hasError('restrictedUser') ? formState.errors.checkin[0] : null}
              label="Notify Care Team"
              name="restrictedUser"
              onChange={handleCheckin}
              checked={checkin}
              variant="outlined"
              disabled={
                !props.auth.accountableAdmin &&
                !props.auth.program &&
                !props.auth.programProvider &&
                !props.auth.provider
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="completedGoal-label" shrink>
              Family Contact
            </InputLabel>
            <Checkbox
              className={classes.textField}
              error={hasError('restrictedUser')}
              fullWidth
              helperText={hasError('restrictedUser') ? formState.errors.checkin[0] : null}
              label="Family Contact"
              name="restrictedUser"
              onChange={handleFamilyContact}
              checked={familyContact}
              variant="outlined"
              disabled={
                !props.auth.accountableAdmin &&
                !props.auth.program &&
                !props.auth.programProvider &&
                !props.auth.provider
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="completedGoal-label" shrink>
              Clinical Contact
            </InputLabel>
            <Checkbox
              className={classes.textField}
              error={hasError('restrictedUser')}
              fullWidth
              helperText={hasError('restrictedUser') ? formState.errors.checkin[0] : null}
              label="Clinical Contact"
              name="restrictedUser"
              onChange={handleClinicalContact}
              checked={clinicalContact}
              variant="outlined"
              disabled={
                !props.auth.accountableAdmin &&
                !props.auth.program &&
                !props.auth.programProvider &&
                !props.auth.provider
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="completedGoal-label" shrink>
              Essential Info
            </InputLabel>
            <Checkbox
              className={classes.textField}
              fullWidth
              label="Essential Info"
              name="restrictedUser"
              onChange={handleEssentialInfo}
              checked={essentialInfo}
              variant="outlined"
            />
          </FormControl>
          {props.auth.accountableAdmin && (
            <>
              <ButtonGroup
                size="small"
                variant="contained"
                color="primary"
                aria-label="contained primary button group">
                <Button onClick={startRecognition}>Start</Button>
                <Button onClick={stopRecognition}>Stop</Button>
                <Button onClick={generateNote}>Generate Note</Button>
                <Button onClick={generateEmail}>Generate Coordination Email Note</Button>
                <Button onClick={resetTranscription}>Reset</Button>
              </ButtonGroup>
              <div>
                <br />
                <br />
                {transcription}
              </div>
              <div style={{ 'white-space': 'pre-wrap' }}>
                <br />
                <br />
                {emailText}
              </div>
            </>
          )}
        </DialogContent>

        <DialogActions>
          {(props.auth.accountableAdmin ||
            props.auth.program ||
            props.auth.programProvider ||
            props.auth.provider) && (
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              autoFocus
              disabled={!formState.isValid}>
              Save Note
            </Button>
          )}
          {(props.auth.accountableAdmin ||
            props.auth.program ||
            props.auth.programProvider ||
            props.auth.provider) && (
            <Button onClick={handleCancel} variant="contained" color="secondary">
              Cancel
            </Button>
          )}
          {!props.auth.accountableAdmin &&
            !props.auth.program &&
            !props.auth.programProvider &&
            !props.auth.provider && (
              <Button onClick={handleCancel} variant="contained" color="secondary">
                Close
              </Button>
            )}
          {props.editVal &&
            (props.auth.accountableAdmin || props.auth.program || props.auth.programProvider) && (
              <Button
                onClick={handleDelete}
                variant="contained"
                style={{ background: colors.red[600], color: 'white' }}>
                Delete Note
              </Button>
            )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddNote;
